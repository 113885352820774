import React from "react"
import loadable from "@loadable/component"

const BackgroundImage = loadable(() => import("@src/components/background-image/static"))
const Jumbotron = loadable(() => import("./components/jumbotron"))
const MasonryGrid = loadable(() => import("./components/masonry-grid"))
const Undivided = loadable(() => import("./components/undivided"))
const LogoBlock = loadable(() => import("./components/logo-block"))
const NoStage = loadable(() => import("./components/no-stage"))
const PrayerWalk = loadable(() => import("./components/prayer-walk"))

import styles from "./styles.module.scss"

const BackgroundImageStyles = {
  backgroundPosition: "top"
}

const BlazingNewTrails = () => (
  <section>
    <BackgroundImage
      className={styles.background_image}
      image="blazing-new-trails/03--header--mountain@2x.jpg"
      style={BackgroundImageStyles}
      fadeIn={false}
    >
      <Jumbotron />
      <MasonryGrid />
    </BackgroundImage>
    <Undivided />
    <LogoBlock />
    <NoStage />
    <PrayerWalk />
  </section>
)

export default BlazingNewTrails
